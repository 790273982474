const enum Icons {
    AngleRight = 'arrow-right',
    Lamp = 'lamp',
    People = 'people',
    Rocket = 'rocket'
}

export {
    Icons
}
